import React from 'react';
// styles
import '../scss/contact.scss';
// components
import SEO from '../components/SEO';
import ContactEmail from '../components/ContactEmail';
import ContactPhone from '../components/ContactPhone';
import ContactList from '../components/ContactList';
// lib
import handleTransitionProps from '../lib/handle-transition-props';
import revealText from '../lib/reveal-text';

function Contact({ transitionStatus }) {
  handleTransitionProps(transitionStatus);
  revealText(transitionStatus);

  return (
    <div>
      <SEO title="Contact | Falkon Content" />
      <div id="contactReveal" className="hidden contact-wrap">
        <ContactEmail bgColor="#f08f7c" textColor="#000">
          <ContactList />
        </ContactEmail>
        <ContactPhone bgColor="#e1b9af" textColor="#fff">
          <ContactList />
        </ContactPhone>
      </div>
    </div>
  );
}

export default React.memo(Contact);
