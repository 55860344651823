function revealText(transitionStatus) {
  const isServer = typeof window === 'undefined';
  let $content;

  if (isServer) return;

  $content = window.document.getElementById('contactReveal');

  if (transitionStatus === 'entered' && $content) {
    window.setTimeout(() => ($content.style.opacity = 1), 1500);
  }

  if (transitionStatus === 'entered' && !$content) {
    window.setTimeout(() => {
      $content = document.getElementById('contactReveal');
      $content.style.opacity = 1
    }, 2500);
  }
}

export default revealText;
